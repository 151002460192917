export const CONSTANTS = {
  STEPS_ROUTES: {
    "9h2m2rntcf": { value: "9h2m2rntcf", priority: 1 },
    oclnq8ne2v: { value: "oclnq8ne2v", priority: 2 },
    xlwr8le4qw: { value: "xlwr8le4qw", priority: 3 },
    l4mvz97gpd: { value: "l4mvz97gpd", priority: 4 },
    kon7sms29k: { value: "kon7sms29k", priority: 5 },
  },

  GET_ROUTE: {
    1: "9h2m2rntcf",
    2: "oclnq8ne2v",
    3: "xlwr8le4qw",
    4: "l4mvz97gpd",
    5: "kon7sms29k",
  },
};
