import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import downloadIcon from "../../assets/download.svg";
import challengeIcon from "../../assets/challengeIcon.jpg";
import epicWinIcon from "../../assets/epicWinIcon.jpg";
import questIcon from "../../assets/questIcon.jpg";
import badGuyIcon from "../../assets/badGuyIcon.jpg";
import powerUpIcon from "../../assets/powerUpIcon.jpg";
import allyIcon from "../../assets/allyIcon.jpg";
import secretIcon from "../../assets/secretIcon.jpg";
import arrowIcon from "../../assets/arrow.svg";
const options = [
  { label: "Mental", isCorrect: false },
  { label: "Emotional", isCorrect: false },
  { label: "Social", isCorrect: false },
  {
    label: "Physical",
    isCorrect: false,
  },

  { label: "All of the above", isCorrect: true },
];

const informationList = [
  {
    title: "Challenge Yourself",
    description:
      "Bring a gameful mindset to everyday life. Focus on goals & growth.",
    icon: challengeIcon,
  },
  {
    title: "Go For an Epic Win",
    description: "Create a goal that is realistic and energizing.",
    icon: epicWinIcon,
  },
  {
    title: "Complete Quests:",
    description: "Take small daily steps that move you toward your epic win.",
    icon: questIcon,
  },
  {
    title: "Battle the Bad Guys:",
    description: "Find obstacles that block your progress and confront them.",
    icon: badGuyIcon,
  },
  {
    title: "Activate Power-ups:",
    description:
      "Do simple actions that make you feel stronger, healthier & happier.",
    icon: powerUpIcon,
  },
  {
    title: "Recruit Your Allies:",
    description:
      "Take a minute to check in each day with people who support you.",
    icon: allyIcon,
  },
  {
    title: "Adopt a Secret Identity:",
    description: "Pick a heroic nickname that inspires you.",
    icon: secretIcon,
  },
];
const Task2 = ({
  showFeedbackModal,
  handleProgressValue,
  handleTaskComplete,
  analytics,
}) => {
  const [isQuestionContainer, setIsQuestionContainer] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheck = (answer) => {
    setAnswer(answer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (answer === -1) {
      return;
    }
    if (options[answer]?.isCorrect) {
      setIsSubmitting(true);
      const result = await handleTaskComplete();
      if (result) {
        showFeedbackModal({ isInCorrect: false });
      }
      setIsSubmitting(false);
      analytics("answered");
    } else {
      showFeedbackModal({ isInCorrect: true });
    }
  };

  const handleContinue = () => {
    setIsQuestionContainer(true);
    handleProgressValue(50);
  };

  useEffect(() => {
    handleProgressValue(37.5);
  }, [handleProgressValue]);
  const IntroductionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-32 fs-sm-24 fw-600">
            The Power of Living Gamefully{" "}
          </div>
          <div className=" fs-sm-16 fs-24 ls-3">
            You can use the psychology of game play to be stronger, happier and
            braver in everyday life.
            <div>
              Studies show that playing SuperBetter improves mental health,
              optimism, social support and the belief in one's ability to
              achieve their goals.
            </div>
          </div>
          <div className="fw-600 d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2 fs-sm-18 fs-24 ls-3">
            <div>Learn the 7 rules:</div>
            <a
              href="https://superbetter-production.s3.us-east-2.amazonaws.com/asafile/how-to-play.pdf"
              download="Rules"
              className="color-p fs-18 mt-1 fs-sm-14"
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Download PDF{" "}
              <Image width={18} height={18} src={downloadIcon} alt="download" />
            </a>
          </div>
        </Col>
        <Col sm={12}>
          <Row className="gy-3 gx-4 g-0 mt-sm-0">
            {informationList.map((item, index) => (
              <Col key={index} sm={12} md={6} xl={4} className="">
                <Card className="box-shadow-1 border-card">
                  <Card.Body className="d-flex justify-content-between">
                    <div>
                      <div className="text-black-1 fs-20 fw-600 ">
                        {index + 1}. {item.title}
                      </div>
                      <div className="text-black-2 fs-18 op-60 ls-3">
                        {item.description}
                      </div>
                    </div>
                    <div>
                      <Image src={item.icon} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Button
            className="continue-btn d-flex gap-3 justify-content-between align-items-center px-3 px-sm-4 mt-2 mt-sm-3 rounded-pill py-2 py-sm-2"
            onClick={handleContinue}
          >
            <div>Continue</div>
            <Image src={arrowIcon} alt="download" />
          </Button>
        </Col>
      </>
    );
  };

  const questionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-32 fs-sm-24 fw-600">
            Grow Your Resilience{" "}
          </div>
          <div className=" fs-sm-16 fs-24 ls-3">
            Resilience is the ability to stay strong, motivated and optimistic
            even in the face of change and difficult challenges.
            <div>
              It's an important skill to develop for school and career success.
            </div>
          </div>
          <div className="color-s fs-24 fs-sm-18  fw-600">Task 2 Question</div>

          <div className="fw-700 fs-sm-16 fs-20 ls-3">
            From Jane's TED Talk, what type of resilience is improved by playing
            SuperBetter?{" "}
          </div>
        </Col>
        <Col sm={12} className="d-flex ">
          <Form onSubmit={handleSubmit}>
            {options.map((option, index) => (
              <div key={option.label} className="mb-2 mb-sm-3">
                <Form.Check type={"radio"} id={option.label}>
                  <Form.Check.Input
                    onChange={() => handleCheck(index)}
                    type={"radio"}
                    checked={index === answer}
                  />
                  <Form.Check.Label
                    className={`${
                      index === answer ? "fw-600" : ""
                    } ms-2 fs-20 fs-sm-14`}
                  >
                    {option.label}
                  </Form.Check.Label>
                </Form.Check>
              </div>
            ))}

            <Button
              disabled={answer === -1 || isSubmitting}
              type="submit"
              className="default-btn px-3 px-sm-4 mt-2 mt-sm-3 rounded-pill py-2 py-sm-2"
            >
              {isSubmitting ? "Submitting..." : "Submit Answer"}
            </Button>
          </Form>
        </Col>
      </>
    );
  };
  return (
    <Row className="justify-content-center align-items-center gap-3 gap-sm-3">
      {isQuestionContainer ? questionContainer() : IntroductionContainer()}
    </Row>
  );
};

export default Task2;
