import React, { useCallback, useEffect, useState } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CONSTANTS } from "../../utils/const";
import TopContainer from "./TopContainer";
import StepsContainer from "./StepsContainer";
import { getUserTask } from "../../services/taskCreatore";
import { recordEvent } from "../../services/analyticsCreator";
import { queryParams } from "../../utils/helper";
import { Spinner } from "react-bootstrap";
import ScrollToTop from "../../utils/scrollToTop";

function Steps() {
  const { step } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrollToTop, setIsScrollToTop] = useState(false);
  const userId = searchParams.get("user_id");
  const uniqueTaskKey = searchParams.get("unique_task_key");
  const [progressValue, setProgressValue] = useState(0);
  const stepNumber = CONSTANTS.STEPS_ROUTES[step]?.priority;

  const analytics = useCallback((pageId, eventName) => {
    recordEvent({
      pageId,
      eventName,
      userId,
      uniqueTaskKey,
    });
  }, [uniqueTaskKey, userId]);

  const handleProgressValue = useCallback((value) => {
    setProgressValue(value);
    setIsScrollToTop(true);
  }, []);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const result = await getUserTask(userId);
      if (result) {
        setCurrentStep(result.data ? result.data.stepNumber + 1 : 1);
      }
      setIsLoading(false);
    })();
  }, [userId]);

  useEffect(() => {
    if (currentStep && currentStep <= 5) {
      navigate(
        `/${CONSTANTS.GET_ROUTE[currentStep]}?${queryParams({
          user_id: userId,
          unique_task_key: uniqueTaskKey,
          step_number: stepNumber,
        })}`
      );
    } else if (currentStep > 5) {
      navigate("/");
    }
  }, [currentStep, navigate, stepNumber, uniqueTaskKey, userId]);

  useEffect(() => {
    if (isScrollToTop) {
      setIsScrollToTop(false);
    }
  }, [isScrollToTop]);

  useEffect(() => {
    if (currentStep === stepNumber) {
      analytics(`task-${stepNumber}`, 'visit');
    }
  }, [currentStep, stepNumber, analytics]);

  if (!CONSTANTS.STEPS_ROUTES[step]) {
    return <Navigate to={"/"} />;
  }

  return (
    <div
      id="layout"
      className={`tasks d-flex flex-column gap-3 gap-sm-4 step-${stepNumber}`}
    >
      {isScrollToTop && <ScrollToTop />}
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner size="sm" animation="grow" variant="success" />
          <Spinner size="sm" animation="grow" variant="danger" />
          <Spinner size="sm" animation="grow" variant="warning" />
        </div>
      ) : (
        <>
          <TopContainer taskStep={stepNumber} progressValue={progressValue} />
          <StepsContainer
            taskStep={stepNumber}
            handleProgressValue={handleProgressValue}
            setCurrentStep={setCurrentStep}
            analytics={(pageId, eventName) => analytics(pageId, eventName)}
          />
        </>
      )}
    </div>
  );
}

export default Steps;
