export function queryParams(obj = {}, url = "") {
  let params = [];
  for (let key in obj) {
    if (key !== "sort" && key !== "order" && obj[key]) {
      params.push(`${key}=${obj[key]}`);
    }
  }

  if (obj.sort) {
    params.push(
      `sort=${obj.sort},${obj.order && obj.order === "asc" ? `desc` : "asc"}`
    );
  }

  if (!params.length) {
    return url ? url : "";
  }

  return url ? `${url}?${params.join("&")}` : params.join("&");
}
