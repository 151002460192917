import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./styles/main.scss";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <ToastContainer position="bottom-center" limit={1} />
  </>
);
