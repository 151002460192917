import React from "react";
import { Container } from "react-bootstrap";

import SeoLayout from "../common/SeoLayout";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <SeoLayout title="EvolveMe">
      <Container fluid={true} className="p-0 ">
        <Outlet />
      </Container>
    </SeoLayout>
  );
}

export default Layout;
