import React from "react";
import { Button as Btn } from "react-bootstrap";

function Button({
  label,
  customizeClass,
  type = "button",
  disabled,
  onClick,
  customProps,
  variant,
}) {
  return (
    <>
      <Btn
        className={`${customizeClass} default-btn bg-color-p`}
        variant={variant ? variant : "primary"}
        type={type}
        disabled={disabled}
        onClick={onClick}
        {...customProps}
      >
        {label}
      </Btn>
    </>
  );
}

export default Button;
