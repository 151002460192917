import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    const layout = document.getElementById("layout");
    layout && (layout.scrollTop = 0);
    layout && (layout.scrollLeft = 0);
  }, [pathname]);

  return null;
}
