import React from "react";
import { Button as BootStrapButton, Modal } from "react-bootstrap";
import Button from "../Buttons";
import { EVOLVE_ME_URL } from "../../services/constants";

function LeaveModal({ show, handleClose, analytics }) {
  return (
    <Modal size="md" show={show} centered backdrop="static">
      <Modal.Body className="rounded-4 px-4">
        <div className="d-flex flex-column justify-content-center text-center my-3 gap-2 ">
          <div className="text-black-1 fs-32 lh-sm fw-600 fs-sm-32">
            Are you sure you want to leave?
          </div>
          <div className="text-black-5 fs-18 fw-600 fs-sm-16">
            You won’t receive points for incomplete tasks.
          </div>
        </div>
        <div className="d-flex flex-column justify-content-md-center mt-5 mb-3">
          <div className="fst-italic text-black-5 fs-16 fs-sm-14 text-center text-sm-start">
            You'll receive points for completed tasks, and we'll save your
            progress so you can return later to finish.
          </div>
          <div className="d-flex flex-column mt-3 flex-sm-row justify-content-center gap-3 gap-sm-2">
            <BootStrapButton
              className={"continue-btn px-4 py-3 w-100 rounded-pill "}
              onClick={handleClose}
            >
              No. I’ll stay
            </BootStrapButton>
            <Button
              customizeClass={" px-4 py-3 w-100 rounded-pill "}
              label={"Yes. Return to EvolveMe"}
              onClick={() => {
                analytics("redirected");
                window.open(EVOLVE_ME_URL, "_self");
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LeaveModal;
