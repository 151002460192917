import { toast } from "react-toastify";
import { BASE_URL, ENDPOINTS } from "./constants";

export async function completeTask(payload) {
  try {
    const response = await fetch(BASE_URL + ENDPOINTS.COMPLETE_TASKS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (!data.status) {
      throw data;
    }
    return data;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
}
export async function getUserTask(id) {
  try {
    const response = await fetch(BASE_URL + ENDPOINTS.GET_TASKS(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!data.status) {
      throw data;
    }
    return data;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
}
