import React from "react";
import { ReactComponent as Task1 } from "../../assets/steps/task-1.svg";
import { ReactComponent as Task3 } from "../../assets/steps/task-3.svg";
import { ReactComponent as Task5 } from "../../assets/steps/task-5.svg";
import { ReactComponent as Task2 } from "../../assets/steps/task-2-mobile.svg";
import { ReactComponent as Task4 } from "../../assets/steps/task-4.svg";
import logo from "../../assets/logo.svg";
import { Col, Image, Row } from "react-bootstrap";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

const taskSteps = [
  {
    label: "task 1",
    title: "Watch Video",
    icon: <Task1 />,
    stepNo: 1,
    min: 0,
    max: 25,
  },
  {
    label: "task 2",
    title: "Learn Rules",
    icon: <Task2 />,
    stepNo: 2,
    min: 25,
    max: 50,
  },
  {
    label: "task 3",
    title: "Epic Win",
    icon: <Task3 />,
    stepNo: 3,
    min: 50,
    max: 75,
  },
  {
    label: "task 4",
    title: "Bad Guys",
    icon: <Task4 />,
    stepNo: 4,
    min: 75,
    max: 100,
  },
  {
    label: "task 5",
    title: "Power-ups",
    icon: <Task5 />,
    stepNo: 5,
    min: 0,
    max: 0,
  },
];

const MobileStepper = ({ page, onPageNumberClick, value, step }) => {
  const task = taskSteps.find((task) => task.stepNo === step);
  return (
    <Row className="multiple-stepper d-flex  justify-content-between align-items-start">
      <Col
        className={`step col-12 justify-content-between d-flex align-items-center gap-2 `}
      >
        <div className="d-flex gap-2 gap-lg-3 justify-content-between align-items-center">
          <div
            className={`icon-container border-0 p-1  progressing`}
            style={{ width: "4.5rem", height: "4.5rem" }}
          >
            <CircularProgressbarWithChildren
              value={step}
              minValue={0}
              maxValue={5}
              strokeWidth={7}
              styles={buildStyles({
                trailColor: "#f2f2f2",
                pathColor: "#4A60AB",
              })}
            >
              <div className="icon d-flex justify-content-center align-items-center">
                {task.icon}
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div
            className={`d-flex flex-column gap-0 progressing  bottom-container `}
          >
            <div className="fs-sm-14 ls-1 fw-600 text-uppercase task ">
              {task.label}/5
            </div>
            <div className="title fs-sm-20 fw-600 ">{task.title}</div>
          </div>
        </div>
        <div className={` `}>
          <Image src={logo} />
        </div>
      </Col>
    </Row>
  );
};

export default MobileStepper;
