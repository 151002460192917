import { ENDPOINTS } from "./constants";

export async function recordEvent(payload) {
  try {
    await fetch(ENDPOINTS.ANALYTICS_EVENT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    return true;
  } catch (error) {
    return false;
  }
}