import React from "react";
import { ReactComponent as Task1 } from "../../assets/steps/task-1.svg";
import { ReactComponent as Task3 } from "../../assets/steps/task-3.svg";
import { ReactComponent as Task5 } from "../../assets/steps/task-5.svg";
import { ReactComponent as Task2 } from "../../assets/steps/task-2.svg";
import { ReactComponent as Task4 } from "../../assets/steps/task-4-mobile.svg";
import { ReactComponent as CompletedIcon } from "../../assets/steps/tick-circle.svg";
import { Button, Col, ProgressBar, Row } from "react-bootstrap";

const taskSteps = [
  {
    label: "task 1",
    title: "Watch TED Talk",
    icon: <Task1 />,
    stepNo: 1,
    min: 0,
    max: 25,
  },
  {
    label: "task 2",
    title: "How It Works",
    icon: <Task2 />,
    stepNo: 2,
    min: 25,
    max: 50,
  },
  {
    label: "task 3",
    title: "Epic Wins",
    icon: <Task3 />,
    stepNo: 3,
    min: 50,
    max: 75,
  },
  {
    label: "task 4",
    title: "Bad Guys",
    icon: <Task4 />,
    stepNo: 4,
    min: 75,
    max: 100,
  },
  {
    label: "task 5",
    title: "Power-up!",
    icon: <Task5 />,
    stepNo: 5,
    min: 0,
    max: 0,
  },
];

const MultiStepProgressBar = ({ value, step }) => {
  return (
    <Row className="multiple-stepper d-flex  justify-content-between align-items-start">
      {taskSteps.map((task, ind) => (
        <Col
          key={task.label}
          className={`step col-auto d-flex flex-column gap-2 ${
            ind < taskSteps.length - 1 ? "flex-grow-1" : ""
          } `}
        >
          <div className="d-flex gap-2 gap-lg-3 justify-content-between align-items-center">
            <div
              className={`icon-container ${
                task.stepNo < step
                  ? "completed"
                  : task.stepNo === step
                  ? "progressing"
                  : ""
              }`}
            >
              <div className="icon d-flex justify-content-center align-items-center">
                {task.stepNo < step ? <CompletedIcon /> : task.icon}
              </div>
            </div>
            {ind < taskSteps.length - 1 && (
              <ProgressBar
                className={`w-100  ${
                  task.stepNo < step
                    ? "completed"
                    : task.stepNo === step
                    ? "progressing"
                    : ""
                }`}
                min={task.min}
                max={task.max}
                now={value}
              />
            )}{" "}
          </div>
          <div
            className={`d-flex flex-column gap-1  bottom-container ${
              task.stepNo < step
                ? "completed"
                : task.stepNo === step
                ? "progressing"
                : ""
            }`}
          >
            <div className="fs-16 ls-1 fw-600 text-uppercase task ">
              {task.label}
            </div>
            <div className="title fs-20 fw-600 ">{task.title}</div>
            <div>
              {task.stepNo <= step && (
                <Button
                  className={`w-auto button ${
                    task.stepNo < step
                      ? "btn-completed"
                      : task.stepNo === step
                      ? "btn-progress"
                      : ""
                  }`}
                  style={{ pointerEvents: "none" }}
                >
                  {task.stepNo < step
                    ? "Completed"
                    : task.stepNo === step
                    ? "In Progress"
                    : ""}
                </Button>
              )}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default MultiStepProgressBar;
