import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";

import epicWinIcon from "../../assets/epicWinIcon1.jpg";
import questIcon from "../../assets/questIcon1.jpg";

import arrowIcon from "../../assets/arrow.svg";
const options = [
  { label: "A snowboarding accident", isCorrect: false },
  { label: "A bad breakup", isCorrect: false },
  { label: "A serious concussion", isCorrect: true },
  {
    label: "Failing a big test",
    isCorrect: false,
  },
];

const informationList = [
  {
    title: "Choose an Epic Win",
    description:
      "Write down one goal you would like to accomplish this week. It can be small, large or a step toward something bigger. The best epic wins are realistic & energizing.",
    icon: epicWinIcon,
  },
  {
    title: "Identify a Quest",
    description:
      "Write down one action you can complete today to move you closer to your epic win.",
    icon: questIcon,
  },
];
const Task3 = ({
  showFeedbackModal,
  handleProgressValue,
  handleTaskComplete,
  analytics,
}) => {
  const [isQuestionContainer, setIsQuestionContainer] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheck = (answer) => {
    setAnswer(answer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (answer === -1) {
      return;
    }
    if (options[answer]?.isCorrect) {
      setIsSubmitting(true);
      const result = await handleTaskComplete();
      if (result) {
        showFeedbackModal({ isInCorrect: false });
      }
      setIsSubmitting(false);
      analytics('answered');
    } else {
      showFeedbackModal({ isInCorrect: true });
    }
  };

  const handleContinue = () => {
    setIsQuestionContainer(true);
    handleProgressValue(75);
  };

  useEffect(() => {
    handleProgressValue(62.5);
  }, [handleProgressValue]);
  const IntroductionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-1">
          <div className="fst-italic text-center fs-sm-13 text-black-3 mt-n4 fs-20 ls-3">
            In the remaining tasks you'll need a place to capture notes as you
            practice the rules. Use a note app, a journal or whatever you
            choose.
          </div>
          <div className="color-s fs-32 fs-sm-24 fw-600">
            Go for an Epic Win{" "}
          </div>
          <div className=" fs-sm-16  fs-24 ls-3">
            And Identify Your First Quest
          </div>
        </Col>
        <Col sm={12}>
          <Row className="gy-3 gx-4 g-0 mt-sm-0">
            {informationList.map((item, index) => (
              <Col key={index} sm={12} md={6} className="">
                <Card className="box-shadow-1 border-card h-100 text-center">
                  <Card.Body className="d-flex flex-column align-items-center justify-content-start gap-2 gap-sm-3">
                    <div className="w-100">
                      <Image className="w-70" src={item.icon} />
                    </div>
                    <div className="text-black-1 text-uppercase fw-600 fs-20 ls-10 fs-sm-18 ">
                      {item.title}
                    </div>
                    <div className="text-black-2 fs-sm-14 fs-20 op-60 ls-3">
                      {item.description}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Button
            className="continue-btn d-flex gap-3 justify-content-between align-items-center px-3 px-sm-4 mt-2 mt-sm-3 rounded-pill py-2 py-sm-2"
            onClick={handleContinue}
          >
            <div>Continue</div>
            <Image src={arrowIcon} alt="download" />
          </Button>
        </Col>
      </>
    );
  };

  const questionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-24 fs-sm-18  fw-600">Task 3 Question </div>

          <div className="fw-700 fs-sm-16 fs-20 ls-3">
            In her TED Talk, what did Jane invent SuperBetter to recover from?
          </div>
        </Col>
        <Col sm={12} className="d-flex ">
          <Form onSubmit={handleSubmit}>
            {options.map((option, index) => (
              <div key={option.label} className="mb-4 mb-sm-3">
                <Form.Check type={"radio"} id={option.label}>
                  <Form.Check.Input
                    onChange={() => handleCheck(index)}
                    type={"radio"}
                    checked={index === answer}
                  />
                  <Form.Check.Label
                    className={`${
                      index === answer ? "fw-600" : ""
                    } ms-2 fs-20 fs-sm-14`}
                  >
                    {option.label}
                  </Form.Check.Label>
                </Form.Check>
              </div>
            ))}

            <Button
              disabled={answer === -1 || isSubmitting}
              type="submit"
              className="default-btn px-3 px-sm-4 mt-4 mt-sm-3 rounded-pill py-2 py-sm-2"
            >
              {isSubmitting ? "Submitting..." : "Submit Answer"}
            </Button>
          </Form>
        </Col>
      </>
    );
  };
  return (
    <Row className="justify-content-center align-items-center gap-3 gap-sm-3">
      {isQuestionContainer ? questionContainer() : IntroductionContainer()}
    </Row>
  );
};

export default Task3;
