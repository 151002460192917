import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Layout from "./pages/layout";
import Steps from "./pages/steps";
import Home from "./pages/Home";
import NotFound from "./pages/notFound";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  const RouteElement = () => {
    let element = useRoutes([
      {
        path: "/",
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/:step",
            element: <Steps />,
          },

          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ]);
    return element;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer position="bottom-center" limit={1} />
      <RouteElement />
    </BrowserRouter>
  );
}

export default App;
