import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";

import badGuyIcon from "../../assets/badGuyIcon1.jpg";
import allyIcon from "../../assets/allyIcon1.jpg";
import battleIcon from "../../assets/battleIcon.jpg";

import arrowIcon from "../../assets/arrow.svg";
const options = [
  { label: "Her sister Kelly", isCorrect: true },
  { label: "Her doctor", isCorrect: false },
  { label: "Her dog", isCorrect: false },
  {
    label: "A random stranger",
    isCorrect: false,
  },
];

const informationList = [
  {
    title: "Spot a Bad Guy",
    description: (
      <div>
        <div>A bad guy is any obstacle that blocks your progress.</div>
        <div>
          Write down an obstacle that may negatively impact your ability to
          achieve your epic win. Bonus if you give the bad guy a silly name to
          take away some of its power.
        </div>
      </div>
    ),
    icon: badGuyIcon,
  },
  {
    title: "Create a Battle Strategy",
    description: (
      <div>
        <div>
          Now come up with at least one idea for how you can battle the bad guy.
        </div>
        <div>Write it down.</div>
      </div>
    ),
    icon: battleIcon,
  },
  {
    title: "Choose your first Ally",
    description:
      "Allies are trusted friends who support you. They can suggest quests, help you spot a bad guy, activate a power-up with you and celebrate your epic wins. Think of a potential ally and write down their name.",
    icon: allyIcon,
    hasHint: true,
  },
];
const Task4 = ({
  showFeedbackModal,
  handleProgressValue,
  handleTaskComplete,
  analytics,
}) => {
  const [isQuestionContainer, setIsQuestionContainer] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheck = (answer) => {
    setAnswer(answer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (answer === -1) {
      return;
    }
    if (options[answer]?.isCorrect) {
      setIsSubmitting(true);
      const result = await handleTaskComplete();
      if (result) {
        showFeedbackModal({ isInCorrect: false });
      }
      setIsSubmitting(false);
      analytics('answered');
    } else {
      showFeedbackModal({ isInCorrect: true });
    }
  };
  const handleContinue = () => {
    setIsQuestionContainer(true);
    handleProgressValue(100);
  };

  useEffect(() => {
    handleProgressValue(87.5);
  }, [handleProgressValue]);
  const IntroductionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-32 fs-sm-24 fw-600">
            Find & Battle the Bad Guys{" "}
          </div>
          <div className=" fs-sm-16 fs-24 ls-3">And Identify an Ally</div>
        </Col>
        <Col sm={12}>
          <Row className="gy-3 gx-4 g-0 mt-sm-0">
            {informationList.map((item, index) => (
              <Col key={index} sm={12} className="">
                <Card className="box-shadow-1 border-card">
                  <Card.Body className="d-flex text-center text-sm-start flex-column flex-sm-row align-items-center align-items-sm-start gap-2 gap-sm-4">
                    <div>
                      <Image src={item.icon} />
                    </div>
                    <div>
                      <div className="text-black-1 text-uppercase fs-24 fs-sm-18 fw-600 ">
                        {item.title}
                      </div>
                      <div className="text-black-2 text-start  fs-20 fs-sm-18 op-60 ls-3">
                        {item.description}
                      </div>
                      {item.hasHint && (
                        <div className="text-black-2 my-2 my-sm-3 fs-18  fs-sm-18 op-60 ls-3">
                          <div className="fw-700 text-start">
                            Need help thinking of an ally? This made-up scenario
                            may help: 
                          </div>
                          <div className="d-flex text-start mt-3 bg-white-3 rounded-4 flex-column gap-3 p-3 p-sm-4">
                            <div>
                              Oh no! A meteor struck planet Earth, and its
                              cosmic rays have turned millions of people into
                              mutants with unpredictable superpowers. Guess
                              what? You are now one of those mutants with
                              superpowers. You’re pretty sure you can figure out
                              a way to use them for good. But in the meantime,
                              the government is after you. 
                            </div>
                            <div>
                              Who can you trust with your secret? Who will you
                              tell the truth to about your superpowers? Who can
                              help you to figure out what to do with
                              them? Consider the first person who comes to mind.
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <Button
            className="continue-btn d-flex gap-3 justify-content-between align-items-center px-3 px-sm-4 mt-2 mt-sm-3 rounded-pill py-2 py-sm-2"
            onClick={handleContinue}
          >
            <div>Continue</div>
            <Image src={arrowIcon} alt="download" />
          </Button>
        </Col>
      </>
    );
  };

  const questionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-24 fs-sm-18  fw-600">Task 4 Question </div>
          <div className="fw-700 fs-sm-16 fs-20 ls-3">
            Thinking back to the TED Talk, who did Jane invite to be her first
            ally?{" "}
          </div>
        </Col>
        <Col sm={12} className="d-flex ">
          <Form onSubmit={handleSubmit}>
            {options.map((option, index) => (
              <div key={option.label} className="mb-4 mb-sm-3">
                <Form.Check type={"radio"} id={option.label}>
                  <Form.Check.Input
                    onChange={() => handleCheck(index)}
                    type={"radio"}
                    checked={index === answer}
                  />
                  <Form.Check.Label
                    className={`${
                      index === answer ? "fw-600" : ""
                    } ms-2 fs-20 fs-sm-14`}
                  >
                    {option.label}
                  </Form.Check.Label>
                </Form.Check>
              </div>
            ))}

            <Button
              disabled={answer === -1 || isSubmitting}
              type="submit"
              className="default-btn px-3 px-sm-4 mt-4 mt-sm-3 rounded-pill py-2 py-sm-2"
            >
              {isSubmitting ? "Submitting..." : "Submit Answer"}
            </Button>
          </Form>
        </Col>
      </>
    );
  };
  return (
    <Row className="justify-content-center align-items-center gap-3 gap-sm-3">
      {isQuestionContainer ? questionContainer() : IntroductionContainer()}
    </Row>
  );
};

export default Task4;
