import { Button, Modal } from "react-bootstrap";

const FeedbackModal = ({
  show,
  title,
  description,
  handleClose,
  handleConfirm,
  confirmText,
  cancelText,
  isInCorrect,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      centered
      backdrop="static"
      contentClassName=" common-content align-items-center justify-content-center w-100 bg-transparent border-0"
      backdropClassName=""
      bsPrefix="modal custom-modal "
      dialogClassName=""
      fullscreen
    >
      <Modal.Header className="border-0 w-100  p-0 d-flex  justify-content-center gap-3">
        <div
          className={`fs-60 fs-sm-32 w-90 text-center fw-700 ${
            isInCorrect ? "incorrect" : "correct"
          } `}
        >
          {title}
        </div>
      </Modal.Header>
      {description && (
        <div className=" d-flex gap-2 col-sm-10 col-lg-6 px-0  text-center">
          <div className="fs-32 w-100 fs-sm-18 fw-600 ls-3 description">
            {description}
          </div>
        </div>
      )}
      <Modal.Footer className="d-flex mt-3 border-0 p-0 justify-content-center   flex-sm-nowrap ">
        {cancelText && (
          <Button
            className={`text-nowrap btn-cancel p-3 px-4 rounded-pill fs-18 `}
            onClick={handleClose}
            size="sm"
          >
            <span className="px-4 px-sm-3 fs-18  fw-600">{cancelText}</span>
          </Button>
        )}
        <Button
          type="button"
          size="sm"
          className={` rounded-pill btn-confirm px-4 p-3 `}
          onClick={handleConfirm}
        >
          <span className="px-5 fs-18  fw-600">{confirmText}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
