import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import VideoPlayer from "../videoPlayer";

const options = [
  { label: "I wish I hadn’t worked so hard", isCorrect: false },
  { label: "I wish I had stayed in touch with my friends", isCorrect: false },
  { label: "I wish I had let myself be happier", isCorrect: false },
  {
    label: "I wish I had the courage to express my real self",
    isCorrect: false,
  },
  {
    label:
      "I wish I’d lived a life true to my dreams, instead of what others expected of me",
    isCorrect: false,
  },
  { label: "All of the above", isCorrect: true },
];
const Task1 = ({
  showFeedbackModal,
  handleProgressValue,
  handleTaskComplete,
  analytics,
}) => {
  const [isQuestionContainer, setIsQuestionContainer] = useState(false);
  const [answer, setAnswer] = useState(-1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStartVideo = () => {
    analytics("video-start");
  };

  const handleFinishVideo = (e) => {
    handleProgressValue(25);
    setIsQuestionContainer(true);
    analytics("video-complete");
  };

  const handleCheck = (answer) => {
    setAnswer(answer);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (answer === -1) {
      return;
    }
    if (options[answer]?.isCorrect) {
      setIsSubmitting(true);
      const result = await handleTaskComplete();
      if (result) {
        showFeedbackModal({ isInCorrect: false });
      }
      setIsSubmitting(false);
      analytics("answered");
    } else {
      showFeedbackModal({ isInCorrect: true });
    }
  };

  useEffect(() => {
    handleProgressValue(12.5);
  }, [handleProgressValue]);
  const videoContainer = () => {
    return (
      <>
        <Col
          sm={12}
          lg={8}
          className="text-sm-center flex-column d-flex gap-3 gap-sm-3"
        >
          <div className="color-s fs-32 fs-sm-24 fw-600">
            Watch Jane McGonigal’s TED Talk
          </div>
          <div className=" fs-20 fs-sm-16 ls-3">
            Reality is broken, says Jane, and we need to make it work more like
            a game.
            <div>
              Watch Jane's inspiring story of resilience & healing that led to
              inventing SuperBetter.
            </div>
          </div>
          <div className="fw-700 fs-20 fs-sm-16 ls-3">
            Earn points in each task by answering a multiple choice question
            about Jane's talk.
          </div>
        </Col>
        <Col sm={12} lg={7} className="d-flex justify-content-center">
          <VideoPlayer onStart={handleStartVideo} onEnded={handleFinishVideo} />
        </Col>
      </>
    );
  };

  const questionContainer = () => {
    return (
      <>
        <Col sm={12} className=" flex-column d-flex gap-3 gap-sm-3">
          <div className="color-s fs-24 fs-sm-18  fw-600">Task 1 Question </div>

          <div className="fw-700 fs-sm-16 fs-20 ls-3">
            Which of the following is among the top regrets of the dying?
          </div>
        </Col>
        <Col sm={12} className="d-flex ">
          <Form onSubmit={handleSubmit}>
            {options.map((option, index) => (
              <div key={option.label} className="mb-4 mb-sm-3">
                <Form.Check type={"radio"} id={option.label}>
                  <Form.Check.Input
                    onChange={() => handleCheck(index)}
                    type={"radio"}
                    checked={index === answer}
                  />
                  <Form.Check.Label
                    className={`${
                      index === answer ? "fw-600" : ""
                    } ms-2 fs-20 fs-sm-14`}
                  >
                    {option.label}
                  </Form.Check.Label>
                </Form.Check>
              </div>
            ))}

            <Button
              disabled={answer === -1 || isSubmitting}
              type="submit"
              className="default-btn px-3 px-sm-4 mt-4 mt-sm-3 rounded-pill py-2 py-sm-2"
            >
              {isSubmitting ? "Submitting..." : "Submit Answer"}
            </Button>
          </Form>
        </Col>
      </>
    );
  };
  return (
    <Row className="justify-content-center align-items-center gap-3 gap-sm-3">
      {isQuestionContainer ? questionContainer() : videoContainer()}
    </Row>
  );
};

export default Task1;
