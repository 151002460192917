import React from "react";
import MultiStepProgressBar from "../../common/stepper/MultiStepProgressbar";
import MobileStepper from "../../common/stepper/MobileStepper";

const TopContainer = ({ taskStep, progressValue }) => {
  return (
    <div className="top-container ">
      <div className="d-none d-sm-block">
        <MultiStepProgressBar
          key={progressValue}
          step={taskStep}
          value={progressValue}
        />
      </div>
      <div className="d-block d-sm-none">
        <MobileStepper step={taskStep} />
      </div>
    </div>
  );
};

export default TopContainer;
