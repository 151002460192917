import React, { useState } from "react";
import Task1 from "../../common/tasks/Task1";
import Task2 from "../../common/tasks/Task2";
import Task3 from "../../common/tasks/Task3";
import Task5 from "../../common/tasks/Task5";
import Task4 from "../../common/tasks/Task4";
import FeedbackModal from "../../common/Modal/FeedbackModal";
import LeaveModal from "../../common/Modal/LeaveModal";
import { completeTask } from "../../services/taskCreatore";
import { useSearchParams } from "react-router-dom";

const StepsContainer = ({
  taskStep,
  handleProgressValue,
  setCurrentStep,
  analytics,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("user_id");
  const uniqueTaskKey = searchParams.get("unique_task_key");
  const stepNumber = searchParams.get("step_number");

  const handleAnalytics = (eventName) => {
    analytics(`task-${stepNumber}`, eventName);
  };

  const handleHideModal = () => {
    setShowModal(false);
    setShowLeaveModal(true);
  };

  const handleShowModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };

  const handleHideLeaveModal = () => {
    setShowLeaveModal(false);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    if (modalData?.isInCorrect === false) {
      setCurrentStep(taskStep + 1);
      setModalData(null);
      handleAnalytics(taskStep === 5 ? "continue" : "next-step");
    }
    setShowModal(false);
  };

  const handleTaskComplete = async () => {
    return await completeTask({
      stepNumber: stepNumber,
      uniqueTaskKey: uniqueTaskKey,
      userId: userId,
    });
  };

  return (
    <>
      <div className="step-container ">
        <div className="layout">
          {taskStep === 1 ? (
            <Task1
              handleProgressValue={handleProgressValue}
              showFeedbackModal={handleShowModal}
              handleTaskComplete={handleTaskComplete}
              analytics={(eventName) => handleAnalytics(eventName)}
            />
          ) : taskStep === 2 ? (
            <Task2
              handleProgressValue={handleProgressValue}
              showFeedbackModal={handleShowModal}
              handleTaskComplete={handleTaskComplete}
              analytics={(eventName) => handleAnalytics(eventName)}
            />
          ) : taskStep === 3 ? (
            <Task3
              handleProgressValue={handleProgressValue}
              showFeedbackModal={handleShowModal}
              handleTaskComplete={handleTaskComplete}
              analytics={(eventName) => handleAnalytics(eventName)}
            />
          ) : taskStep === 4 ? (
            <Task4
              handleProgressValue={handleProgressValue}
              showFeedbackModal={handleShowModal}
              handleTaskComplete={handleTaskComplete}
              analytics={(eventName) => handleAnalytics(eventName)}
            />
          ) : taskStep === 5 ? (
            <Task5
              handleProgressValue={handleProgressValue}
              showFeedbackModal={handleShowModal}
              handleTaskComplete={handleTaskComplete}
              analytics={(eventName) => handleAnalytics(eventName)}
            />
          ) : null}
        </div>
      </div>
      {showModal && (
        <FeedbackModal
          show={showModal}
          handleClose={handleHideModal}
          title={
            modalData?.isInCorrect
              ? "That answer is not correct."
              : "Yay! That is correct."
          }
          description={
            modalData?.isInCorrect
              ? ""
              : taskStep === 5
              ? "That’s it for your EvolveMe tasks! You can return to EvolveMe to see all of your points."
              : "You just earned EvolveMe points."
          }
          cancelText={modalData?.isInCorrect ? "" : "Return to EvolveMe"}
          confirmText={
            modalData?.isInCorrect
              ? "Try again"
              : taskStep === 5
              ? "Continue"
              : "Next Task"
          }
          handleConfirm={handleConfirm}
          isInCorrect={modalData?.isInCorrect ? true : false}
        />
      )}{" "}
      <LeaveModal
        show={showLeaveModal}
        handleClose={handleHideLeaveModal}
        analytics={(eventName) => handleAnalytics(eventName)}
      />
    </>
  );
};

export default StepsContainer;
