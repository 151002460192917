import React, { useCallback, useEffect, useRef, useState } from "react";
import video from "../../assets/janeMcGonigalTalk.mp4";
import thumbnail from "../../assets/thumbnail.png";
import subtitle from "../../assets/subtitles.vtt";

import { FaMessage, FaRegMessage } from "react-icons/fa6";
import { RiPlayFill } from "react-icons/ri";
import { VscDebugPause } from "react-icons/vsc";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { IoPlaySkipForward } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";

const playbackOptions = [1, 1.25, 1.5, 1.75, 2];
const VideoPlayer = ({ onStart, onEnded }) => {
  const videoRef = useRef(null);
  const videoPlayerRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [showSubtitles, setShowSubtitles] = useState(true);
  const [videoStarted, setVideoStarted] = useState(false);

  const handleSubtitles = () => {
    setShowSubtitles(!showSubtitles);
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      videoPlayerRef.current.requestFullscreen();
    }
  };

  const escFunction = useCallback((event) => {
    setIsFullScreen((prev) => !prev);
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", escFunction);
    return () => {
      document.removeEventListener("fullscreenchange", escFunction);
    };
  }, [escFunction]);
  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video.paused) {
      if (!videoStarted) {
        onStart();
      }
      video.play();
      setIsPlaying(true);
      setVideoStarted(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handlePlayBack = (value) => {
    const video = videoRef.current;
    if (video.playbackRate) {
      video.playbackRate = value;
    }
    setPlaybackSpeed(value);
  };

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    setCurrentTime(video.currentTime);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedTime = `${minutes}:${String(remainingSeconds).padStart(
      2,
      "0"
    )}`;

    return formattedTime;
  }

  return (
    <div className="video-player " ref={videoPlayerRef}>
      <video
        ref={videoRef}
        autoPlay
        controls={false}
        onTimeUpdate={handleTimeUpdate}
        onEnded={onEnded}
        className="rounded-3"
        poster={thumbnail}
      >
        <source
          src={video}
          // src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/191332/tina.mp4"
          type="video/mp4"
        />
        {showSubtitles && (
          <track
            kind="subtitles"
            label="English"
            srcLang="en"
            src={subtitle}
            default
          />
        )}
        Your browser does not support the video tag.
      </video>
      <button
        style={{ outline: "none" }}
        onClick={togglePlayPause}
        className={` ${
          isPlaying ? "play" : ""
        } text-white  p-2 position-absolute top-50 start-50 translate-middle rounded-circle border-0 bg-white-op-15  `}
      >
        {isPlaying ? (
          <VscDebugPause className="fs-45  fs-sm-32" />
        ) : (
          <RiPlayFill className="fs-45  fs-sm-32" />
        )}
      </button>
      <div className="controls d-flex w-100 flex-column">
        <div className="w-100">
          <input
            type="range"
            className="w-100"
            value={Number(currentTime / videoRef.current?.duration || 0) * 100}
            onChange={(e) => e.preventDefault()}
          />
        </div>

        <div className="w-100 d-flex justify-content-between  align-items-start">
          <div className="d-flex pb-2 align-items-center">
            <button onClick={togglePlayPause} className="text-white">
              {isPlaying ? (
                <VscDebugPause className="fs-24 fs-sm-20" />
              ) : (
                <RiPlayFill className="fs-24 fs-sm-20" />
              )}
            </button>
            <div className="text-white">
              {" "}
              {formatTime(currentTime)}/
              {formatTime(videoRef.current?.duration || 0)}
            </div>
          </div>
          <div className="pb-2 d-flex align-items-start">
            <button onClick={handleSubtitles}>
              {showSubtitles ? (
                <FaMessage className="fs-20 fs-sm-18" />
              ) : (
                <FaRegMessage className="fs-20 fs-sm-18" />
              )}
            </button>
            <Dropdown
              drop="start"
              align="start"
              className="d-inline mx-2"
              data-bs-theme="dark"
            >
              <Dropdown.Toggle
                className="p-0 d-flex align-items-center fs-24 fs-sm-20"
                id="dropdown-autoclose-true"
              >
                <IoPlaySkipForward />
              </Dropdown.Toggle>

              <Dropdown.Menu align="start">
                {playbackOptions.map((value) => (
                  <Dropdown.Item
                    key={value}
                    className={`${playbackSpeed === value ? "active" : ""}`}
                    onClick={() => handlePlayBack(value)}
                  >
                    {value + "x"}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <button onClick={handleFullScreen}>
              {isFullScreen ? (
                <BiExitFullscreen className="fs-24 fs-sm-20" />
              ) : (
                <BiFullscreen className="fs-24 fs-sm-20" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
