import React, { useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Image, Row } from "react-bootstrap";

import { EVOLVE_ME_URL } from "../../services/constants";
import { recordEvent } from "../../services/analyticsCreator";

import logo from "../../assets/logo.svg";
import playStoreIcon from "../../assets/playStoreIcon.png";
import appStoreIcon from "../../assets/appleStoreIcon.jpg";

const Home = () => {
  const [searchParams] = useSearchParams();

  const userId = searchParams.get("user_id");
  const uniqueTaskKey = searchParams.get("unique_task_key");

  const analytics = useCallback(
    (eventName) => {
      recordEvent({
        pageId: "success",
        eventName,
        userId,
        uniqueTaskKey,
      });
    },
    [uniqueTaskKey, userId]
  );

  useEffect(() => {
    analytics("visit");
  }, [analytics]);

  return (
    <Row className=" g-0 p-4 p-sm-5 flex-column align-items-center justify-content-center gap-3 gap-sm-4 ">
      <Col className="col-auto">
        <div className={` `}>
          <Image width={98} height={98} src={logo} />
        </div>
      </Col>
      <Col
        sm={10}
        xl={8}
        className="text-center d-flex flex-column gap-sm-3 gap-2"
      >
        <div className="color-s fs-60 fs-sm-24 fw-600">Keep Going</div>
        <div className="fs-32 fs-sm-18 fw-600 ls-3 lh-sm">
          Congratulations on completing all the tasks! If you find this approach
          to boosting school & career success intriguing, take the next step and
          download the SuperBetter app. Choose a free Player account to join
          squad challenges hosted by others or a Hero account to play on your
          own.
        </div>
        <hr className="d-sm-none" />
        <div className="mt-0 mt-sm-4 color-p fs-24 fs-sm-24 fw-700">
          Get the app
        </div>
        <div className="d-flex flex-column flex-sm-row gap-3 justify-content-center align-items-center">
          <a
            className="cursor-pointer"
            href="https://apps.apple.com/in/app/superbetter-mental-health/id536634968"
            target="_blank"
            rel="noreferrer"
            onClick={() => analytics("app-store")}
          >
            <Image src={appStoreIcon} />
          </a>
          <a
            className="cursor-pointer"
            href="https://play.google.com/store/apps/details?id=com.superbetter.paid&pli=1"
            target="_blank"
            rel="noreferrer"
            onClick={() => analytics("google-play")}
          >
            <Image src={playStoreIcon} />
          </a>
        </div>
        <hr className="d-sm-none" />
      </Col>
      <Col
        sm={10}
        xl={7}
        className="text-center mt-0 mt-sm-5 d-flex flex-column gap-sm-3 gap-2"
      >
        <div className="fs-24 fs-sm-16 fw-700 ls-3 lh-sm">
          P.S. Your Teachers Can Use SuperBetter, Too
        </div>
        <div className=" fs-20 fs-sm-16 ">
          With Squad Play, teachers can promote mental health & social-emotional
          skills by hosting weekly squad challenges for their students. It's a
          way to foster a culture of gameful wellbeing in their classrooms. Feel
          free to mention SuperBetter to your teachers.
        </div>
        <div>
          <Button
            className={"continue-btn mt-3 mt-sm-4 px-4 py-2 rounded-pill "}
            onClick={() => {
              analytics("redirected");
              window.open(EVOLVE_ME_URL, "_self");
            }}
          >
            Return to EvolveMe
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Home;
