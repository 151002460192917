import React, { forwardRef } from "react";
import { Helmet } from "react-helmet";

const SeoLayout = forwardRef(
  ({ children, title = "", descriptionContent, robot, keywords }, ref) => (
    <>
      <Helmet>
        {/* {robot && <meta name="robots" content="noindex, nofollow" />} */}

        <title>{title ? ` ${title} | SuperBetter` : "SuperBetter"}</title>
        <meta name="description" content={descriptionContent} />
        {keywords && <meta name="keywords" content={keywords} />}
      </Helmet>
      <>{children}</>
    </>
  )
);

export default SeoLayout;
